import "@babel/polyfill";
import "custom-event-polyfill";

if ("NodeList" in window && !NodeList.prototype.forEach) {
  // console.info('polyfill for IE11');
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}

const router = (classList) => {
  let rout = "base.js";

  if (classList.contains("entry-home")) {
    rout = "home.js";
  }

  if (classList.contains("entry-longPage")) {
    rout = "longPage.js";
  }

  if (classList.contains("entry-articleList")) {
    rout = "articleList.js";
  }

  if (
    classList.contains("entry-article") ||
    classList.contains("entry-lnuQuiz")
  ) {
    rout = "article.js";
  }

  if (
    classList.contains("entry-workshop") ||
    classList.contains("entry-activities")
  ) {
    rout = "workshop.js";
  }

  if (classList.contains("entry-coursesAndLearning")) {
    rout = "coursesAndLearning.js";
  }

  if (classList.contains("entry-activities")) {
    rout = "coursesAndLearning.js";
  }

  if (classList.contains("entry-courses")) {
    rout = "coursesAndLearning.js";
  }

  if (classList.contains("entry-coursesAndTeachingPlan")) {
    rout = "coursesAndTeachingPlan.js";
  }

  if (classList.contains("entry-quiz")) {
    rout = "quiz.js";
  }

  if (classList.contains("entry-teachingPlan")) {
    rout = "teachingPlan.js";
  }

  import("./routes/" + rout).then((module) => {
    const newPage = new module.default();
    newPage.init();
    document.body.classList.remove("opacity-0");

    if (newPage.intro) {
      window.addEventListener("load", () => newPage.intro());
    }
  });
};

router(document.querySelector("body").classList);
